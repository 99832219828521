// Here you can add other styles
body{
  font-size: 14px;
}
.error-text{
    color: red;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    text-indent: initial;
}
.admin_chose_page_number{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: #000000;
	height: 36px;
  line-height: 55px;
  float: left;
}
.btn-check:focus + .btn, .btn:focus, .btn:active:focus{
  box-shadow: none;
}

.div_multiline {
  white-space: pre-wrap;
}

.breadcrumb-item.active {
  color: #171718;
}

.credit-item{
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.admin_page_number{
	cursor: pointer;
	margin-left: 5px;
}
.admin_page_number.active{
	color: #048D0B;
}
.align_right{
	text-align: right;
}

.sidebar-nav .nav-link img{
  margin-right: 10px;
}

// .pl-20{
//   padding-left: 20px;
// }

// .pl-40{
//   padding-left: 40px;
// }

// .pl-60{
//   padding-left: 60px;
// }

// .pl-80{
//   padding-left: 80px;
// }

.dropdown-item .green{
  background-color: #06740c;
  color: white;
}

.header-title{
  text-align: center;
  width: 100%;
  color: #06740c;
  font-weight: bold;
}

html:not([dir=rtl]) .sidebar-nav .pl-20 .nav-group-items .nav-link {
  padding-left: 20px;
}

html:not([dir=rtl]) .sidebar-nav .pl-40 .nav-group-items .nav-link {
  padding-left: 40px;
}

html:not([dir=rtl]) .sidebar-nav .pl-60 .nav-group-items .nav-link {
  padding-left: 60px;
}

html:not([dir=rtl]) .sidebar-nav .pl-80 .nav-group-items .nav-link {
  padding-left: 80px;
}

.sidebar-nav .pl-20 .nav-group-items .nav-item .nav-link{
  padding-left: 20px !important;
  cursor: pointer;
}

.sidebar-nav .pl-40 .nav-group-items .nav-item .nav-link{
  padding-left: 40px !important;
  cursor: pointer;
}

.sidebar-nav .pl-60 .nav-group-items .nav-item .nav-link{
  padding-left: 60px !important;
  cursor: pointer;
}

.sidebar-nav .pl-80 .nav-group-items .nav-item .nav-link{
  padding-left: 80px !important;
  cursor: pointer;
}

.remove_file{
  cursor: pointer;
}
.admin_table{
	min-width: 1300px;
}
.modal-content .admin_table{
  min-width: 500px;
  width: 100%;
}
.modal-content .admin_table td{
  height: 26px;
}
.modal-content .bg-antiquewhite{
  background-color: antiquewhite;
}

.modal-content .css-1d8n9bt{
  height: 30px;
  padding-top: 0px !important;
}

.modal-content .css-1wy0on6{
  height: 30px;
}

.modal-content .form-label {
  margin-bottom: 0.1rem;
}
.modal-content .form-control {
  padding: 0.15rem 0.25rem;
}
.modal-content .table > :not(caption) > * > * {
  padding: 0.2rem 0.2rem;
}
.modal-content .btn.small{
  height: 30px;
  line-height: 30px;
  padding-top: 0px;
}
.modal-content .rs-picker-date{
  height: 31px !important;
  margin-top: 1px;  
  width: 100% !important;
}
.modal-content .rs-picker-toggle.rs-btn{
  padding-top: 2px;
  padding-bottom: 2px;
}
.modal-content .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
  top: 4px;
}
.modal-content .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
  top: 4px;
}
.modal-content .select__control--is-focused{
  min-height: 30px !important;
  height: 30px !important;
}
.modal-content .css-1s2u09g-control{
  min-height: 30px !important;
  height: 30px;
}
.modal-content .css-1pahdxg-control{
  height: 30px;
}
.modal-content .css-319lph-ValueContainer{
  margin-top: -3px;
}
.modal-content .css-1hb7zxy-IndicatorsContainer{
  height: 30px;
}
.modal-content .css-b62m3t-container{
  height: 30px;
}
.modal-content .width-100-50{
  width: calc(100% - 50px);
}
.modal-content .width-100-30{
  width: calc(100% - 30px);
}
.modal-content .btn.small{
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding-top: 0px;
}

.narrow_form .css-1d8n9bt{
  height: 30px;
  padding-top: 0px !important;
}
.narrow_form .css-1wy0on6{
  height: 30px;
}
.narrow_form .css-1s2u09g-control{
  min-height: 30px !important;
  height: 30px;
}
.narrow_form .css-1pahdxg-control{
  height: 30px;
}
.narrow_form .css-319lph-ValueContainer{
  margin-top: -3px;
}
.narrow_form .css-1hb7zxy-IndicatorsContainer{
  height: 30px;
}
.narrow_form .css-b62m3t-container{
  height: 30px;
}

.w-100px{
  width: 100px;
}
.w-150px{
  width: 150px;
}
.w-200px{
  width: 200px;
}
.w-2000px{
  width: 2000px;
}
.w-1600px{
  width: 1600px;
}
.w-1800px{
  width: 1800px;
}
.admin_table_wrapper{
	margin-top: 10px;
}
.min_w_1300{
  min-width: 1300px;
}
.min_w_1100{
  min-width: 1100px;
}
.attach_file_download a{
  text-decoration: none;
}
.admin_account_table_wrapper{
	margin-top: 10px;
	overflow-x: auto;
}
.bordered {
	//width: 100%;
	border-collapse: separate !important;
  border: solid #B9B9B9 1px; 
	border-radius: 4px;
}
.bordered th:first-child {
    -moz-border-radius: 4px 0 0 0;
    -webkit-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
}
// .bordered th:last-child {
//     -moz-border-radius: 0 4px 0 0;
//     -webkit-border-radius: 0 4px 0 0;
//     border-radius: 0 4px 0 0;
// }
.bordered th:only-child{
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}
.bordered tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 4px;
    -webkit-border-radius: 0 0 0 4px;
    border-radius: 0 0 0 4px;
}
.bordered tr:last-child td:last-child {
    -moz-border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
    border-radius: 0 0 4px 0;
}
.bordered thead th{
	border-bottom: 1px solid #B9B9B9;
}
.bordered td{
	border-bottom: 1px solid #B9B9B9;
}
.bordered td, .bordered th {
    border-right: 1px solid #B9B9B9;
}
// .bordered th:last-child, .bordered td:last-child{
// 	// border-right: none;
// }	
//.bordered tbody tr:last-child td{
//	border-bottom: none;
//}
.admin_table th{
	padding: 0px;
	height: 36px;
	line-height: 36px;
	background: rgba(222, 222, 222, 1);
	padding-left: 12px;
}
.admin_table input[type=checkbox]{
  margin-right: 0px;
}
.admin_table td{
	padding: 2px 5px;
	height: 36px;
	padding-left: 5px;
	flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 130%;
}
.orderable{
	cursor: pointer;
	user-select: none;
}
.w-150{
  width: 150px;
}
.w-200{
  width: 200px;
  margin: auto;
}
.mt-24{
  margin-top: 24px;
}
.table-responsive {
  overflow-x: unset;
  -webkit-overflow-scrolling: touch;
}
.card-title {
  font-size: 22px;
  font-weight: bold;
  color: firebrick;
}
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  
.header_title{
	float: left;
  width: calc(100% - 10px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 150%;
  text-align: center;
}
.header_sort{
	float: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_sort_up{
	height: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 4px;
	cursor: pointer;
}
.header_sort_down{
	height: 16px;
    display: flex;
    justify-content: center;
    align-items: baseline;
	cursor: pointer;
}

.cell_image {
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}
.cell_image img{
	width: 34px;
    height: 34px;
    border-radius: 4px;
}
.cell_edit {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}
.cell_edit input[type=checkbox]{
	margin-right: 0px;
}
.checkbox_wp{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox_label{
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
}
.percel_label{
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
}

.inline-input{
  width: 300px;
  display: inline;
  margin-left: 20px;
}

.paginatoin-area {
	background: #ffffff none repeat scroll 0 0;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	margin: 10px 0 0;
  }
  .paginatoin-area p {
	margin: 0;
  }
  .pagination-box {
	  text-align: center;	
    margin-bottom: 0px;
  }
  .pagination-box > li {
	  display: inline-block;	
    line-height: 24px;
  }
  .pagination-box > li.active a {
	  //width: 32px;
    height: 30px;
    color: #309335;
    background: #048D0B;
    border-radius: 4px;
    color: #fff !important;
    padding-top: 3px;
    text-align: center;
  }
  .pagination-box > li a img{
	  margin-top: -2px;
  }
  .pagination-box > li a {
	  color: #333333;
	  display: block;
	  padding: 0 10px;
	  margin-left: 5px;
    margin-right: 5px;
	  //width: 32px;
  }
  .pagination-box > li a:hover {
	  color: #309335;
  }
  
.pagination-box a{
	cursor: pointer;
}
.paginatoin-area.basic{
  margin-top: 10px;
}
.Previous, .Next{
    display: block;
    width: 32px;
    height: 30px;
    background: #CFCFCF;
    border-radius: 4px;
    padding-top: 3px !important;
    text-align: center;
}
.heigh_min_500{
  min-height: calc(100vh - 250px);
}
.admin_popover_item {
  font-size: 16px;
  cursor: pointer;
  height: 36px;
  width: 124px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
}
.admin_popover_item.disabled{
	color: #747474;
}
.admin_popover_item img{
	margin-left: 10px;
	margin-right: 7px;
}
.admin_popover_item:hover{
	background-color: #E6F4EC;	
}
.admin_popover_item.long{
	width: 174px;
}
.popover-body {
  padding: 10px!important;
}
.multi-line{
  white-space: pre-line;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.4);
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  align-items: flex-start;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 0px;
  padding-bottom: 50px;
}

.modal-content {
  position: relative;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
  max-height: 100vh;
  overflow: auto;
}

.modal-content, .modal-dialog-centered {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.login_modal {
  font-size: 15px;
}

.login_modal>.header {
  width: 100%;
  padding: 10px 5px;
  height: 40px;
  min-height: 30px;
  background-color: #efefef;
  font-size: 19px;
  padding-top: 5px;
  color: #309335;
  font-weight: bold;
  border-radius: 0.3rem 0.3rem 0px 0px;
  padding-left: 10px;
  display: block;
  text-align: center;
}

.login_modal>.content {
  width: calc(100% - 8px);
  padding: 5px 5px;
}

.error_modal {
  font-size: 15px;
}

.error_modal>.header {
  width: 100%;
  padding: 10px 5px;
  height: 40px;
  min-height: 30px;
  background-color: #efefef;
  font-size: 19px;
  padding-top: 5px;
  color: #309335;
  font-weight: bold;
  border-radius: 0.3rem 0.3rem 0px 0px;
  padding-left: 10px;
  display: block;
  text-align: center;
}

.error_modal>.content {
  width: 100%;
  padding: 10px 10px;
}

.error_popup .row{
  max-height: calc(100vh - 300px);
  overflow-y: auto;  
  margin-right: -10px;
}

.record_count{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  float: left;
}


.scrollbar {
	margin-left: 22px;
	float: left;
	height: 300px;
	width: 65px;
	background: #7A7A7A;
	overflow-y: scroll;
	margin-bottom: 25px;
}

.error_popup .row::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}
.error_popup .row::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0,0,0,0.1);
	border: 1px solid #ccc;
}  
.error_popup .row::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #7A7A7A;
	border: 1px solid #aaa;
}  
.error_popup .row::-webkit-scrollbar-thumb:hover {
	background: #7A7A7A;
}  
.error_popup .row::-webkit-scrollbar-thumb:active {
	background: linear-gradient(to left, #22ADD4, #1E98BA);
}

.admin_a_1, .admin_a_2 {
  height: 36px;
  background: #e7f3e7;
  border-radius: 4px;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 36px;
  color: #2d2d2d;
  padding-left: 8px;
}

.admin_a_1 {
  width: 300px;
}

.admin_a_2 {
  width: 125px;
  margin-left: 10px;
}

.clear_both {
  clear: both;
}

.admin_a_3 {
  margin-top: 14px;
}

.material_paging{
  text-align: center;
  width: 315px;
}

.material_exactly{
  text-align: center;
  width: 150px;
  margin-top: 15px;
}

.material_clear{
  margin-left: -20px;
  margin-top: 15px;
  cursor: pointer;
}

.reload_products{
  cursor: pointer;
}

.material_find_icon{
  margin-top: 16px;
  margin-left: 10px;
}
.material_find_input{
  width: 324px;
  margin-left: 10px;
  margin-top: 9px;
}
.width_find_input{
  width: 150px;
  margin-left: 10px;
  margin-top: 9px;
}

.general_task_border{
  overflow-x: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.general_task_wrapper{
  margin-top: 10px;
  position: relative;
  padding: 5px;
  width: max-content;
}

.general_task_wrapper::after {
  content: "";
  clear: both;
  display: table;
}

.general_task_item{
  width: 350px;
  display: inline;
  float: left;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-left: 5px;
  min-height: 450px;
  padding: 5px;
}
.general_task_item.status_1{  
  background:rgb(44 56 74 / 29%)
}
.general_task_item.status_2{  
  background:#34c3ff85
}
.general_task_item.status_3{
  background:#ffff0036
}
.general_task_item.status_4{
  background:#f4433661
}
.general_task_item.status_5{
  background:#5ba0ef
}

.general_task_item .content{
  min-height: 370px;
}

.general_task_item .column {
  float: left;
  width: 25%;
  text-align: center;
}

.general_task_item .row{
  padding: 0px 30px 0px 20px;
}

.general_task_item .column img{
  cursor: pointer;
}

.general_task_item .row:after {
  content: "";
  display: table;
  clear: both;
}

.narrow_form .select__control--is-focused{
  min-height: 30px !important;
  height: 30px !important;
}
.narrow_form .css-1s2u09g-control{
  min-height: 30px !important;
  height: 30px;
}
.narrow_form .css-1insrsq-control{
  min-height: 30px !important;
  height: 30px;
}
.narrow_form .css-1pahdxg-control{
  height: 30px;
}
.narrow_form .css-319lph-ValueContainer{
  margin-top: -3px;
}
.narrow_form .css-1hb7zxy-IndicatorsContainer{
  height: 30px;
}
.narrow_form .css-b62m3t-container{
  height: 33px;
}

.input_category_name, .input_option_3 {
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2d2d2d;
  padding-left: 10px;
}

.input_category_name {
  width: 300px;
}

.input_category_code {
  width: 125px;
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2d2d2d;
  padding-left: 10px;
  margin-left: 10px;
}

.pt-25{
  padding-top: 25px;
}

.orange{
  color: orange;
}
.green{
  color: green;
}
.pink{
  color: pink;
}

.button_cancel, .button_save {
  height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 5px;
  cursor: pointer;
}

.button_cancel {
  width: 50px;
  border: 1px solid #747474;
  border-radius: 4px;
  color: #747474;
}

.button5 {
  margin-right: 3px;
}

.button5, .button6 {
  float: right;
}

.button_save {
  width: 93px;
  border: 1px solid #048d0b;
  border-radius: 4px;
  color: #048d0b;
  margin-right: 10px;
}

input:focus-visible, select:focus-visible {
  outline: none;
}

.admin_table .cell_center{
  text-align: center;
  padding-left: 0px;
}

.login_label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #4f4f4f;
}

.form-control{
  box-shadow: none;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.inline_right{
  display: inline;
  float: right;
}
.ml-10{
  margin-left: 10px;
}
.ml-50{
  margin-left: 50px;
}
.mt-10{
  margin-top: 10px;
}
.rs-picker-default .rs-picker-toggle{
  border: none !important;
  box-shadow: none;
}
.rs-picker-toggle:focus-visible{
  outline: none !important;
}
.rs-picker-toggle-active:focus-visible{
  outline: none !important;
}
.rs-picker-toggle-active{
  border: none !important;
}
.rs-picker-toggle-textbox, .rs-picker-toggle-value, .rs-picker-toggle-placeholder{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95)) !important;
  box-shadow: none;
}
.select-search__input {
  box-shadow: none;
  border: 1px solid #ced4da;
  width: 100%;
  font-family: inherit;
  font-size: 1rem;
  color: #495057;
}
.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #ccc;
}
.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 6px);
  right: 19px;
  width: 8px;
  height: 8px;
}
.select-search-input{
  height: 40px !important;
  box-shadow: none;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.select-search-input:hover{  
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover{
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.select-search-has-focus .select-search-select{
  border: 1px solid #cfcfcf;
  border-radius: 4px;
}
.select-search-select{
  box-shadow: none;
}
.select-search-container{
  --select-search-selected: #cfcfcf;
}
.select-search-options {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #ccc;
  z-index: 999999999;
}
.select-search-row{
  border-bottom: 1px solid #f7f7fa;
  z-index: 999999999;
}
.rs-picker-default .rs-picker-toggle{
  z-index: 0;
}
.select-search-container, .select-search-input{
  width: 100%;
}
.select-search-container{
  --select-search-text: var(--cui-input-color, rgba(44, 56, 74, 0.95));
}
.view_position_btn{
  cursor: pointer;
  color: brown;
}
.modal_header {
  height: 36px;
  width: 100%;
  background: #e6f4ec;
  line-height: 36px;
  font-size: 20px;
  color: #309335!important;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}
.cell_edit {
  width: 36px;
  height: 36px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
:root{
  overflow: hidden;
}


.sidebar{
  /* //width: 260px; */
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  /* margin-left: 10px; */
} 
.sidebar-item{
  padding: 3px 0px;
  display: block;
  transition: background-color .15s;
  cursor: pointer;
}
span.sidebar-item:hover{        
  background: #53a753;
  color: white;
}  
span.sidebar-item.active{        
  background: #53a753;
  color: white;
}  
.sidebar-title{    
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 10px;    
  padding-top: 3px;
  padding-bottom: 3px;
  height: 40px;
  line-height: 32px;
}
.sidebar-item.active > .sidebar-title{          
  background: #53a753;
  color: white;  
}
.sidebar-item > .sidebar-title:hover{          
  background: #53a753;
  color: white;
}
.sidebar-title span i{
  display: inline-block;
  width: 1.5em;
}
.sidebar-title span img{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.sidebar-item img{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-content .sidebar-item.plain{
  padding: 10px;
  padding-left: 50px;
  line-height: 19px;
}
.sidebar-content .sidebar-item.inner.plain{
  padding-left: 35px;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}  
.sidebar-item.plain{
  text-decoration: none;    
  font-size: 16px;
  cursor: pointer;    
  padding-left: 20px;
  height: 40px;
  line-height: 32px;
}
.sidebar-item.plain i{
  display: inline-block;
  width: 1.7em;
}
.bi-chevron-down::before {
  content: "\f282";
  vertical-align: -0px;
}
.flex-grow-1 {
  flex-grow: 1 !important;
  overflow: auto;
  height: calc(100vh - 200px);
}
.card {
  overflow: auto;
  height: calc(100vh - 110px);
  padding-bottom: 10px;
}
.chart_1_wrapper{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.chart_2_wrapper{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.select__menu{
  z-index: 999999999;
}
.center{
  text-align: center;
}
.left{
  text-align: left !important;
}
.right{
  text-align: right;
}
.pl-0{
  padding-left: 0px !important;
}
.inline{
  display: inline;
}
.pointer{
  cursor: pointer;
}
.main_tab{
  width: 400px;
}
.cell_edit {
  width: 36px;
  height: 36px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-clip: content-box;
  border: 1.5px solid #bbb;
  border-radius: 5px;
  margin-right: 15px;
  vertical-align: sub;
}

input[type=checkbox]:checked {
  background-image: url(icons/checked.png);
  border: 0;
}

.btn, .btn:hover, .btn:active, .btn:focus{
  text-decoration: none;
}

.tooltip-wrap {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  bottom: 5%;
  left: 5%;
  right: 5%;
  background-color: #fff;
  padding: .5em;
  min-width: 10rem;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #048d0b;
  --cui-btn-border-color: #048d0b;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #026407;
  --cui-btn-hover-border-color: #037608;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #026407;
  --cui-btn-active-border-color: #026407;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #037608;
  --cui-btn-disabled-border-color: #037608;
}

.btn-primary-2 {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #048d6e;
  --cui-btn-border-color: #048d6e;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #036e55;
  --cui-btn-hover-border-color: #036e55;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #036e55;
  --cui-btn-active-border-color: #036e55;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #036e55;
  --cui-btn-disabled-border-color: #036e55;
}

.btn-primary-3 {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #55a1ed;
  --cui-btn-border-color: #55a1ed;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #1388fc;
  --cui-btn-hover-border-color: #1388fc;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #1388fc;
  --cui-btn-active-border-color: #1388fc;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #1388fc;
  --cui-btn-disabled-border-color: #1388fc;
}

.special_icon{
  float: right;
  margin-right: 20px;
  margin-top: 6px;
  cursor: pointer;
}

.special_icon_code{
  float: right;
  margin-right: -7px;
  margin-top: 6px;
  cursor: pointer;
}

.special_name{
  margin-left: -16px;
  padding-right: 0px;
  padding-left: 0px;
}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.margin-top--15{
  margin-top: -15px;
}

.margin-top--5{
  margin-top: -5px;
}

.margin-top--10{
  margin-top: -10px;
}

.righ_label{
  height: 36px;
  line-height: 36px;
}

.error{
  color: red !important;
}

.card{
  border-radius: 0;
}

.tab_primary{
  float: left;
  width: auto;
  display: -webkit-box;
}

.tab_primary .btn{
  margin-right: 10px;
}

.table_wrapper{
  width: 100%;
  overflow: auto;
}

.admin_table_head{
  position: sticky;
  top: 0px;
  background: #ccc;
  z-index: 999;
}

.sticky-left-head{
  position: sticky;
  left: 0px;
  background: #ccc !important;
}

.sticky-left-body{
  position: sticky;
  left: 0px;
  background: #fff;
}

.sticky-right-head{
  position: sticky;
  right: 0px;
}

.sticky-right-body{
  position: sticky;
  right: 0px;
  background: #fff;
}

.searchBtn{
  margin-top: -6px;
}

.card-body{
  padding-top: 8px !important;
}

.margin_0{
  margin: 0px;
}

.pdf_export{
  padding: 10px;
  width: 880px;
}

.pdf_wrapper{
  border: 1px solid #ccc;
}

.display_none{
  display: none;
}

.pdf_export td{
  border-width: 1px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    font-size: 22px;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.chosse_from_web{  
  cursor: pointer;
  color: #048d0b;
}

.mr-20{
  margin-right: 20px;
}

.mr-10{
  margin-right: 10px;
}

.ml-20{
  margin-left: 20px;
}

.ml-10{
  margin-left: 10px;
}

.ml-30{
  margin-left: 30px;
}

.ml-40{
  margin-left: 40px;
}

.tab_primary .btn{
  margin-top: 10px;
}

.export_btn{
  display: inline;
  margin-left: 10px;
}

.header-nav li a{
  text-decoration: none;
}

.header-nav li a .full_name{
  font-weight: 500;
  text-align: center;
}

.header-nav li a .organization{
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.header-nav .nav-link:hover{
  color: var(--cui-header-color);
}

.header-nav .show > .nav-link{
  color: var(--cui-header-color);
}

.rs-picker-date-menu{
  z-index: 9999;
}

.h-350{
  height: 350px;
}

.head-row{
  background: green;
  color: white;
}

.remark{
  font-style: italic;
  padding-top: 20px;
}

.italic{
  font-style: italic;
}

.total_line{
  font-weight: bold;
}

.w-100p-30{
  width: calc(100% - 30px);
}
.w-100p{
  width: 100%;
}

.select__control--is-disabled{
  background-color: #d8dbe0 !important;
  border-color: #b1b7c1 !important;
}

.select__single-value--is-disabled{
  color: black !important;
}

.button_in_table_row {
  border: 1px solid #747474;
  border-radius: 4px;
  color: #747474;
  display: inline;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.button_in_table_row.active{
  background: green;
  border: 1px solid green;
  color: white;
}

.option_header_2, .option_name_title {
  height: 36px;
  background: #e7f3e7;
  border-radius: 4px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
  color: #2d2d2d;
  padding-left: 15px;
  float: left;
}

.option_name_title {
  width: 172px;
}

.option_header_2 {
  width: 80px;
  margin-left: 14px;
}

.input_option_name, .input_option_short {
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2d2d2d;
  margin-top: 10px;
  float: left;
  position: relative;
}

.input_option_name {
  width: 172px;
}

.input_option_2 button, .input_option_name button, .input_option_short button, .sub_category_input_text button {
  border: none;
  background: #fff;
  color: #00612e;
  font-weight: 700;
  font-size: 17px;
  width: 33px;
  border-radius: 0 5px 5px 0;
  height: 33px;
  position: absolute;
  right: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.input_option_2 input, .input_option_name input, .input_option_short input, .sub_category_input_text input {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
  height: 36px;
  background: transparent;
  border: none;
  line-height: 36px;
  width: -webkit-calc(100% - 33px);
  width: calc(100% - 33px);
  padding: 0 0 0 10px;
}

.input_option_3 {
  width: 80px;
  margin-left: 14px;
  margin-top: 10px;
}

.input_category_name, .input_option_3 {
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2d2d2d;
  padding-left: 10px;
}

.add_property_option_btn {
  width: 360px;
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #747474;
}

.add_property_option_btn img {
  margin-right: 8px;
  margin-top: -2px;
}

.add_property_option_btn {
  width: 360px;
  height: 36px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #747474;
}

.button4 {
  float: left;
}

.button_cancel {
  width: 50px;
  border: 1px solid #747474;
  border-radius: 4px;
  color: #747474;
}

.button_cancel, .button_save {
  height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  margin-top: 9px;
  margin-bottom: 5px;
  cursor: pointer;
}

.button3 {
  margin-left: 207px;
}

.button_save {
  width: 93px;
  border: 1px solid #048d0b;
  border-radius: 4px;
  color: #048d0b;
  margin-right: 10px;
}

.button2, .button3, .button11 {
  float: left;
}

.navbar {
  width: 515px;
  overflow: auto;
}

.vat-percent{
  width: 105px;
  float: left;
  margin-right: 5px
}

.material_name{
  width: calc(100% - 30px);  
  display: inline;
}

.material_icon{
  float: right;
  display: inline;
  margin-left: 5px;
  cursor: pointer;
}

.edit_name_icon{
  display: inline;
  margin-left: 5px;
  cursor: pointer;
}

.inline_icon{
  width: 30px;
  cursor: pointer;
  margin-right: 5px;
  display: inline;
}
.inline_text{
  display: inline;
  float: right;
}

.goods.navbar{
  width: 600px;
}

.akia.navbar{
  width: fit-content;
}

.akia.navbar a {
  margin-right: 5px;
}

.navbar a {
  float: left;
  padding: 12px;
  color: black;
  text-decoration: none;
  font-size: 17px;
}

.navbar a:hover {
  background-color: #04AA6D;
  color: white;
}

.navbar a.active {
  background-color: #04AA6D;
  color: white;
}

.break_line{
  margin-top: 10px;
  height: 1px;
  background: #ccc;
}

.pb-2{
  padding-bottom: 2rem;
}

.mt-5px{
  margin-top: 5px;
}

.modal-backdrop{
  height: 100vh;
}

.relative{
  position: relative;
}

.test-1{
  float: left;
  width: calc(100% - 80px);
}

.test-2{
  float: right;
  width: 75px;
  text-align: center;
}

.test-3{
  float: left;
  width: calc(100% - 40px);
}

.test-4{
  float: right;
  width: 60px;
}

.width-100-80{
  width: calc(100% - 80px);
}

.width-80{
  width: 80px;
}

.absolute_top_right{
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.break_col{
  width: 1px;
  height: 100%;
  background: #ccc;
  border: 1px solid #ccc;
}

.custom_select{
  width: 600px;
  display: none;
  background: white;
  border: 1px solid #ccc;
  z-index: 999;
}

.large_select{
  width: 600px;
  display: none;
  background: white;
  border: 1px solid #ccc;
  z-index: 999;
}

.input_pecent{
  width: 20%;
  display: inline;
  margin-left: 20px;
}
.input_amount{
  width: 25%;
  display: inline;
  margin-left: 50px;
}
.intput_check{
  margin-left: 15px;
  display: inline;
}
.intput_check_ml_0{
  margin-left: 0px;
  display: inline;
}
.material_input .form-label{
  font-style: italic;
}


.quotation_header{
  text-align: center;
  width: 100%;
  font-size: 33px;
  font-weight: bold;
}

.qutation_title{
  text-align: center;
  width: 100%;
}

.trans_cell{
  position: relative;
}

.trans_remark{
  cursor: pointer;
  margin-left: 10px;
  display: inline;
}

.img__description {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(29, 106, 154, 1);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
}

.trans_remark:hover .img__description {
  visibility: visible;
  opacity: 1;
}

.trans_amount{
  display: inline;
}

.order_title{
  text-align: left;
}

.delivery-condition{
  border: 1px solid #ccc;
  padding: 10px;
  width: 1300px;
  border-radius: 4px;
  white-space: pre-line;
}

.make_order .rs-picker-date{
  height: 31px !important;
  margin-top: 1px;  
  width: 100% !important;
}
.make_order .rs-picker-toggle.rs-btn{
  padding-top: 2px;
  padding-bottom: 2px;
}

.make_order .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
  top: 4px;
}
.make_order .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
  top: 4px;
}

.stock-layout-line{
  height: auto;
  border-radius: 5px;
  border: 1px solid #ccc;  
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}

.btn-shelf {
  color: #fff;
  background-color: #d61d99;
  border-color: #d61d99;
  font-size: 0.875rem;
  line-height: 1.3;
  font-family: "Roboto", sans-serif;
  margin-top: 5px;
}

.btn-shelf:hover, .btn-shelf:active, .btn-shelf:focus {
  color: #fff;
  background-color: #ff3e32;
  border-color: #ff3225;
}

.stock-layout-line.selected{
  background-color: aliceblue;
}

.floor_header{
  float: left;
  background: bisque;
  height: 100%;
  width: 74px;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.col-combo-input-button{
  display: flex;
}
.display-inline{
  display: inline;
}
.mt-5px{
  margin-top: 5px;
}

.badge-danger {
  color: #fff;
  background-color: #ff6258;
}

.line-title {
  padding: 10px 40px;
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;
  transform: translate(50%,0);
}

.stock-line-title {
  width: 50%;
  text-align: right;
}

.pt-5px{
  padding-top: 5px;
}

.mr-5px{
  margin-right: 5px;
}

.line-floor {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 62px;
}

.round_small_button {
  border-radius: 35px;
  padding: 3px 8px 7px 8px;
}

.fl-right {
  float: right;
}

.fl-left {
  float: left;
}

.clear-both{
  clear: both;
}

.mdi-plus-box::before {
  content: "\F416";
}

.narrow_form .form-label {
  margin-bottom: 0.1rem;
}
.narrow_form .form-control {
  padding: 0.15rem 0.25rem;
}
.narrow_form .table > :not(caption) > * > * {
  padding: 0.2rem 0.2rem;
}
.narrow_form .btn.small{
  height: 30px;
  line-height: 30px;
  padding-top: 0px;
}
.narrow_form .rs-picker-date{
  height: 31px !important;
  margin-top: 1px;  
  width: 100% !important;
}
.narrow_form .rs-picker-toggle.rs-btn{
  padding-top: 2px;
  padding-bottom: 2px;
}
.narrow_form .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
  top: 4px;
}
.narrow_form .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
  top: 4px;
}

.input-left-label{
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.input-right{
  float: right;
  width: calc(100% - 50px);
}

.no-item-info{
  text-align: center;
  font-size: 16px;
  font-style: italic;
  margin-top: 10px;
}

.center_vertical{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.page-title{
  font-weight: bold;
  font-size: 15px;
}

.color-1{
  background-color: blanchedalmond;
}

.color-2{
  background-color: #b1f0c6;
}

.color-3{
  background-color: rgb(149, 225, 228);
}

.small-button{
  background: #0b93a9;
  color: #fff;
  width: fit-content;
  padding: 0px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.row-button{
  background: #0b93a9;
  color: #fff;
  width: fit-content;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
}

.form-value{
  padding-top: 8px;
}

.plus-icon{
  margin-top: -5px;
}

.center-vertical{
  display: flex;
  align-items: center;
}

.right_100{
  width: 100%;
  text-align: right;
}

.center-vertical input{
  width: 24px;
}

.center-vertical-2{
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.center-vertical-2 .label{
  width: 100px;
}

.form-label{
  margin-bottom: 0px !important;
}

.form-value{
  line-height: 25px;
}

input:read-only, input:read-only:focus {
  background-color: #bdbdb84f;
}

.material_remark{
  font-style: italic;
}

.form-file-upload {
  height: 120px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}
.form-file-upload_small{  
  height: 45px;
}

.input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.wherehouse_quantity_input{
  display: inline;
  width: calc(100% - 26px);
  margin-right: 10px;
}

.admin_table tr td a{
  text-decoration: none;
}

.parcel_input{
  display: inline;
  width: calc(100% - 26px);
  margin-right: 10px;
}

.wherehouse_quantity_img{
  display: inline;
  cursor: pointer;
}

.td_none_border{
  border: none !important;
}

.bold{
  font-weight: bold;
}

.bottom_total_order{
  width: 1486px;
}

.task_1{
  background: antiquewhite;
  border-color: antiquewhite;
}

.task_2{
  background: aquamarine;
  border-color: aquamarine;
}

.task_3{
  background: chartreuse;
  border-color: chartreuse;
}

.task_4{
  background: cadetblue;
  border-color: cadetblue;
}

.task_5{
  background: deepskyblue;
  border-color: deepskyblue;
}

.task_6{
  background: darkgray;
  border-color: darkgray;
}

.invalid_input{
  border: 1px solid red;
}

.attach_wrapter{
  position: relative;
  height: 45px;
}

.attach_wrapter .attach_label{
  padding-top: 22px;
  float: left;
}

.attach_wrapter .attach_file{
  width: calc(100% - 120px);
  float: right;
  padding-top: 10px;
}

.row_bg_red{
  background: rgb(255 0 0 / 8%);
}

.row_bg_strong_red{
  background: rgb(255 0 0 / 29%);
}

.row_bg_orange{
  background: rgb(255 165 0 / 16%);
}

.row_bg_green{
  background: rgb(9 229 170 / 16%);
}

.btn_container {
  display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
}
.btn-info.success{
  color: chartreuse;
}
.btn-info.empty{
  color: white;
}

.btn-small{
  height: 27px;
  padding-top: 0px;
}

.edit-icon{
  margin-left: 10px;
  margin-top: -5px;
  cursor: pointer;
}
.edit-input{
  width: 200px;
  display: inline;
  text-align: right;
}
.h-5{
  height: 50px;
}
.pagination-box{
  padding-left: 0px;
}

.dropdown-item-green{
  background-color: #06740c !important;
  color: white !important;
}

.company_name_side{
  padding: 0px 10px;
  text-align: center;
}

.icon_small img{
  width: 20px;
}

@media only screen and (max-width: 699px) {
  .hide-mobile{
    display:none !important
  }
}

.split-left {
  float: left;
  height: calc(100vh - 180px);
  width: 200px;
  overflow-x: hidden;
  border: 1px solid #ccc;
}

.split-right {
  float: right;
  height: calc(100vh - 180px);
  width: calc(100% - 200px);
  overflow-y: hidden;
  border: 1px solid #ccc;
  border-left: none;
}


.show-messages-header{
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  padding-top: 9px;
  font-size: 20px;
}

.show-messages{
  margin-bottom: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
}

.input-message{
  width: 100%;
  height: 50px;
}

.chat-user{
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-left: 5px;  
	cursor: pointer;
}

.input-message-input{
  height: 100%;
  width: calc(100% - 85px);
  padding-top: 6px;
  padding-left: 10px;
}

.input-message-send{
  padding-top: 6px;
  padding-right: 10px;
}

.your-message{
  text-align: right;
  margin-right: 10px;
  font-size: 16px;
}
.other-people{
  text-align: left;
  margin-left: 10px;
  font-size: 16px;
}
.show-messages-body {
  width: 100%;
  height: calc(100% - 100px);
  border-bottom: 1px solid #ccc;
  overflow-y: scroll;
}
.new-message{
  color: red;
  font-weight: bold;
}
.big-font{
  font-size: 22px;
}
.medium-font{
  font-size: 18px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 2px;
  padding-bottom: 2px;
}

.disabled{
  color: #111;
}

.btn-success.selected{
  background: #2f7f7f;
  border-color: #2f7f7f;
}

.material_image{
  max-width: 100px;
  max-height: 100px;
}

.small-button-2{
  padding: 0px 6px 3px 6px;
}

.pd-20{
  padding-top: 20px;
}

.small_icon_btn{
  padding: 5px;
  height: 29px;
  padding-top: 0px;
}

.small_icon_btn img{
  width: 16px;
  height: 16px;
}

.customer_contact{
  border: solid 1px #ccc;
  border-radius: 4px;
  padding-left: 10px;
  margin-bottom: 5px;
}

.css-lm1rg1{
  width: 80px;
  min-width: unset !important;
  min-height: 80px;
  height: auto !important;
}

.org_tree .css-lm1rg1{
  height: 80px;
  min-height: unset !important;
  min-width: 80px;
  width: auto !important;
}

html:not([dir=rtl]) .sidebar-nav .nav-icon:first-child {
  margin-right: 10px;
  margin-left: 0px;
}